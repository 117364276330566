import React, { Component } from 'react';
import ContainerTranslation from "../partials/containerTranslation";
import illustration from '../../assets/images/illustration.png';
import ecosystem from '../../assets/images/ecosystem.png';
import partner1 from '../../assets/images/firstandforemost.png';
import partner2 from '../../assets/images/uena.png';

class Terms extends Component {

  render() {
    return (
      <div className="about">
        <h1 className="title" style={{textAlign:'center'}}><ContainerTranslation id={"terms.title"}/></h1>
        <div className="withoutSeparator">
          <p className="content"><ContainerTranslation id={"terms.introduction"}/></p>
        </div>
        <div className="withoutSeparator">
          <h2 className="subTitle"><ContainerTranslation id={"terms.title1"}/></h2>
          <p className="content"><ContainerTranslation id={"terms.text1"}/></p>
        </div>
        <div className="withoutSeparator">
          <h2 className="subTitle"><ContainerTranslation id={"terms.title2"}/></h2>
          <p className="content"><ContainerTranslation id={"terms.text2"}/></p>
        </div>
        <div className="withoutSeparator">
          <h2 className="subTitle"><ContainerTranslation id={"terms.title3"}/></h2>
          <p className="content"><ContainerTranslation id={"terms.text3"}/></p>
        </div>
        <div className="withoutSeparator">
          <h2 className="subTitle"><ContainerTranslation id={"terms.title4"}/></h2>
          <p className="content"><ContainerTranslation id={"terms.text4"}/></p>
        </div>
        <div className="withoutSeparator">
          <h2 className="subTitle"><ContainerTranslation id={"terms.title5"}/></h2>
          <p className="content"><ContainerTranslation id={"terms.text5"}/></p>
        </div>
        <div className="withoutSeparator">
          <h2 className="subTitle"><ContainerTranslation id={"terms.title6"}/></h2>
          <p className="content"><ContainerTranslation id={"terms.text6"}/></p>
        </div>
        <div className="withoutSeparator">
          <h2 className="subTitle"><ContainerTranslation id={"terms.title7"}/></h2>
          <p className="content"><ContainerTranslation id={"terms.text7"}/></p>
        </div>
        <div className="withoutSeparator">
          <h2 className="subTitle"><ContainerTranslation id={"terms.title8"}/></h2>
          <p className="content"><ContainerTranslation id={"terms.text8"}/></p>
        </div>
        <div className="baseline">
          <h2 style={{maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', color:'#212934', fontSize:'30px', marginTop:150}}><ContainerTranslation id={"about.baseline"}/></h2>
          <a className="btn" style={{width:160, marginBottom:100}} href="mailto:contact@kelvi.org?subject=Hello!"><ContainerTranslation id={"about.baselineBtn"}/></a>
        </div>
      </div>
    );
  }
}

export default Terms;
