import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Select from 'react-select';
import ContainerTranslation from "../partials/containerTranslation";
import Button from "../partials/Button/Button";
import ReactTooltip from 'react-tooltip'
import { fetchUsers, createUser, updateUser, deleteUser } from '../../actions/user';
import _ from 'lodash';

import List from './../partials/List/List'

class User extends Component {

  constructor(props) {
    super(props);
    const { params, fetchUsers, createUser, updateUser , deleteUser} = this.props;
    this.state = {
      displayAddUser:false,
      userFirstName:"",
      userLastName:"",
      userRole:"Member",
      userId:"",
      userPassword:"",
      userConfirmPassword:"",
      user:null,
      error: false,
    };
    fetchUsers()
  }

  componentDidUpdate(prevProps){
    if(this.props.message && this.props.message !== prevProps.message){
      if(this.props.message === "User successfully updated!" || this.props.message === "User successfully removed!" || this.props.message === 'user saved' || this.props.message === 'user register !'){
        this.props.fetchUsers()
      }
    }
  }

  addUser(elm) {
    var _this= this
    if(_this.state.userPassword !== _this.state.userConfirmPassword){
      return
    }
    if(this.state.user){
      this.props.updateUser({
        profile:{
          firstName:_this.state.userFirstName,
          lastName:_this.state.userLastName,
        },
        email:_this.state.userId,
        role:_this.state.userRole,
        password:_this.state.userPassword
      }, _this.state.user)
    } else {
      this.props.createUser({
        profile:{
          firstName:_this.state.userFirstName,
          lastName:_this.state.userLastName,
        },
        email:_this.state.userId,
        role:_this.state.userRole,
        password:_this.state.userPassword
      })
    }
    this.setState({
      displayAddUser:false,
      userFirstName:"",
      userLastName:"",
      userId:"",
      userPassword:"",
      userConfirmPassword:"",
      userRole:"",
      user:null
    })
  }

  toggleRole(role) {
    this.setState({
      userRole:role
    })
  }

  displayAddUser(elm) {
    this.setState({
      displayAddUser:!this.state.displayAddUser,
      userFirstName:"",
      userLastName:"",
      userRole:"Member",
      userPassword:"",
      userConfirmPassword:"",
      userId:"",
      user:null,
    })
  }

  handleUserIdChange(e){
    this.setState({
      userId:e.currentTarget.value
    })
  }

  handleUserPasswordChange(e){
    this.setState({
      userPassword:e.currentTarget.value
    })
  }
  handleUserConfirmPasswordChange(e){
    this.setState({
      userConfirmPassword:e.currentTarget.value
    })
  }

  handleUserFirstNameChange(e){
    this.setState({
      userFirstName:e.currentTarget.value
    })
  }

  handleUserLastNameChange(e){
    this.setState({
      userLastName:e.currentTarget.value
    })
  }

  editUser(id){
    const _this = this
    let foundUser = _.find(_this.props.users, {'_id':id})
    if(foundUser){
      this.setState({
        user:id,
        userFirstName:foundUser.profile.firstName,
        userLastName:foundUser.profile.lastName,
        userRole:foundUser.role,
        userId:foundUser.email,
        displayAddUser:true
      })
    }
  }

  selectUser(id){
    console.log(id)
  }

  deleteUser(id) {
    var result = window.confirm("Voulez-vous vraiment supprimer ce lit ?");
    if (result) {
      this.props.deleteUser(id)
    }
  }

  render() {
    var userData = this.props.users
    _.each(userData, function(u){
      if(u.profile.firstName){
        u.name = u.profile.firstName+" "+u.profile.lastName
      }else{
        u.name = u.email
      }
    })

    var roleMember = 'inactive'
    if(this.state.userRole === 'Member'){
      roleMember = ''
    }

    var roleOwner = 'inactive'
    if(this.state.userRole === 'Owner'){
      roleOwner = ''
    }

    return (
      <div className="page-container">
        <h1 className="page-title" style={{fontSize: 42, textAlign: 'center', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}><ContainerTranslation id={"user.title"}/></h1>
        {/*<a className="btn hideOnMobile" onClick={this.displayAddUser.bind(this)} style={{marginTop: '-4px', float:'right'}}><ContainerTranslation id={"user.add"}/></a>*/}
        {userData && userData.length ? (
          <List
            data = {userData}
            showAvailable = {false}
            type = "user"
            withTools={true}
            editItem={this.editUser.bind(this)}
            selectItem={this.selectUser.bind(this)}
            deleteItem={this.deleteUser.bind(this)}
          />
        ) : (
          null
        )}
        { this.state.displayAddUser ? (
          <div className="popup">
            <div className="popup-back">
            </div>
            <div className="popup-content">
              <a onClick={this.displayAddUser.bind(this)} className="close">
                <ContainerTranslation id={"global.cancel"}/>
              </a>
              { this.state.userRole === 'Member' ? (
                <div>
                  <label style={{marginTop: '16px'}} htmlFor="userId">
                    <ContainerTranslation id={"user.id"}/>
                  </label>
                  <input name="userId" value={this.state.userId} type="text" placeholder="Identifiant..." onChange={ (e) => this.handleUserIdChange(e) }/>
                  <label style={{marginTop: '16px'}} htmlFor="userPassword">
                    <ContainerTranslation id={"user.password"}/>
                  </label>
                  <input name="userPassword" value={this.state.userPassword} type="text" placeholder="Mot de passe..." onChange={ (e) => this.handleUserPasswordChange(e) }/>
                  <input name="userConfirmPassword" value={this.state.userConfirmPassword} type="text" placeholder="Confirmez le mot de passe..." onChange={ (e) => this.handleUserConfirmPasswordChange(e) }/>
                </div>
              ) : (
                null
              )}
              { this.state.userRole === 'Owner' || this.state.userRole === 'Admin' ? (
                <div>
                  <label style={{marginTop: '16px'}} htmlFor="userFirstName">
                    <ContainerTranslation id={"user.firstName"}/>
                  </label>
                  <input name="userFirstName" value={this.state.userFirstName} type="text" placeholder="Prénom..." onChange={ (e) => this.handleUserFirstNameChange(e) }/>
                  <label style={{marginTop: '16px'}} htmlFor="userLastName">
                    <ContainerTranslation id={"user.lastName"}/>
                  </label>
                  <input name="userLastName" value={this.state.userLastName} type="text" placeholder="Nom..." onChange={ (e) => this.handleUserLastNameChange(e) }/>
                  <label style={{marginTop: '16px'}} htmlFor="userId">
                    <ContainerTranslation id={"user.email"}/>
                  </label>
                  <input name="userId" value={this.state.userId} type="text" placeholder="Email..." onChange={ (e) => this.handleUserIdChange(e) }/>
                </div>
              ) : (
                null
              )}
              <Button
                style={{marginTop:'20px', display:'block', textAlign:'left'}}
                action={this.toggleRole.bind(this, "Member")}
                icon="fas fa-user-md"
                active={roleMember}
                textTranslation="user.member"
              />
              <Button
                style={{marginTop:'10px', marginBottom:'30px', display:'block', textAlign:'left'}}
                action={this.toggleRole.bind(this, "Owner")}
                icon="fas fa-user-cog"
                active={roleOwner}
                textTranslation="user.owner"
              />
              { this.state.userRole === 'Owner' || (this.state.userId !== "" && this.state.userPassword !== "" && this.state.userPassword === this.state.userConfirmPassword) ? (
                <a className="btn" onClick={this.addUser.bind(this)} style={{marginTop: '10px'}}>
                  <ContainerTranslation id={"global.confirm"}/>
                </a>
              ) : (
                <a className="btn" style={{marginTop: '10px', cursor:'not-allowed', opacity:0.2}}>
                  <ContainerTranslation id={"global.confirm"}/>
                </a>
              )}
            </div>
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.user.users,
    message: state.user.message
  };
}

export default withRouter(connect(mapStateToProps, {fetchUsers, createUser, updateUser, deleteUser})(User));
