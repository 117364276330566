import { FETCH_LANGUAGES, CREATE_LANGUAGE, UPDATE_LANGUAGE, DELETE_LANGUAGE, FETCH_LANGUAGE, SELECT_LANGUAGE, LANGUAGE_ERROR } from '../actions/types';

const INITIAL_STATE = { language: null, languages: [], message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_LANGUAGES:
      return { ...state, languages: action.payload.languages, message:'', error: '' };
    case FETCH_LANGUAGE:
      return { ...state, language: action.payload.language, message: '', error: '' };
    case SELECT_LANGUAGE:
      return { ...state, language: action.payload, message: '', error: '' };
    case CREATE_LANGUAGE:
      return { ...state, languages: action.payload.languages, language:action.payload.language, message:action.payload.message, error: '' };
    case UPDATE_LANGUAGE:
      return { ...state, message:action.payload.message, error: '' };
    case DELETE_LANGUAGE:
      return { ...state, language:{}, message: action.payload.message, error: '' };
    case LANGUAGE_ERROR:
      return { ...state, error: action.payload };
  }

  return state;
}
