import { getData, postData, putData, deleteData } from './index';
import { FETCH_LANGUAGES, FETCH_LANGUAGE, SELECT_LANGUAGE, CREATE_LANGUAGE, UPDATE_LANGUAGE, DELETE_LANGUAGE, LANGUAGE_ERROR } from './types';

//= ===============================
// Language actions
//= ===============================

export function createLanguage(language) {
  const data = language;
  const url = '/language';
  return dispatch => postData(CREATE_LANGUAGE, LANGUAGE_ERROR, true, url, dispatch, data);
}

export function fetchLanguages() {
  const url = '/language';
  return dispatch => getData(FETCH_LANGUAGES, LANGUAGE_ERROR, true, url, dispatch);
}

export function fetchLanguage(languageId) {
  const url = '/language/'+languageId;
  return dispatch => getData(FETCH_LANGUAGE, LANGUAGE_ERROR, true, url, dispatch);
}

export function selectLanguage(id) {
  return dispatch => dispatch({
    type: SELECT_LANGUAGE,
    payload: id,
  });
}

export function updateLanguage(language, languageId) {
  const url = '/language/'+languageId
  let data = language
  return dispatch => putData(UPDATE_LANGUAGE, LANGUAGE_ERROR, true, url, dispatch, data);
}

export function deleteLanguage(languageId) {
  const url = '/language/'+languageId;
  return dispatch => deleteData(DELETE_LANGUAGE, LANGUAGE_ERROR, true, url, dispatch);
}
