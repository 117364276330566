import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getForgotPasswordToken } from '../../actions/auth';
import { withRouter } from 'react-router';
import ContainerTranslation from "../partials/containerTranslation";

const form = reduxForm({
  form: 'forgotPassword',
});

class ForgotPassword extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.history.push('/');
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.authenticated) {
      this.props.history.push('/');
    }
  }

  handleFormSubmit(formProps) {
    this.props.getForgotPasswordToken(formProps);
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="message error">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    } else if (this.props.message) {
      return (
        <div className="message">
          <strong>Success!</strong> We sent you an email to reset your password
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="page">
        <div className="page-login">
          <div className="col-login">
            <div className="header-login">
              <h1 style={{marginBottom: 0}}>Forgot password</h1>
            </div>
            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} className="form-login">
              {this.renderAlert()}
              {/*<h1>Login</h1>*/}
              <div className="row" style={{textAlign: 'left'}}>
                <label className="" htmlFor="email"><ContainerTranslation id={"profile.email"}/></label>
                <Field name="email" placeholder="email" id="email" className="form-control" component="input" type="text" />
              </div>
              <button type="submit" className="btn btn-submit" style={{marginBottom:50}}><ContainerTranslation id={"global.next"}/></button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    authenticated: state.auth.authenticated,
  };
}

export default withRouter(connect(mapStateToProps, { getForgotPasswordToken })(form(ForgotPassword)));
