import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ContainerTranslation from "../partials/containerTranslation";
import logo from '../../assets/images/logo.svg';

class FooterTemplate extends Component {

  render() {
    const d = new Date();
    const year = d.getFullYear();

    return (
      <footer>
        <img src={logo} style={{marginTop:16}}/>
        <ul className="navbar">
          <li key={`${1}footer`}>
            <a href="mailto:contact@kelvi.org?subject=Hello!"><ContainerTranslation id={"header.contact"}/></a>
            <a href="mailto:contact@kelvi.org?subject=Need help!" style={{marginLeft:30}}><ContainerTranslation id={"header.help"}/></a>
            <Link to="/about" style={{marginLeft:30}}><ContainerTranslation id={"header.about"}/></Link>
            <Link to="/terms" style={{marginLeft:30}}><ContainerTranslation id={"header.terms"}/></Link>
            <Link to="/privacy" style={{marginLeft:30}}><ContainerTranslation id={"header.privacy"}/></Link>
          </li>
        </ul>
      </footer>
    );
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
  };
}

export default connect(mapStateToProps, null)(FooterTemplate);
