import { FETCH_QUESTIONS, CREATE_QUESTION, INIT_MESSAGE_QUESTION, UPDATE_QUESTION, DELETE_QUESTION, FETCH_QUESTION, QUESTION_ERROR } from '../actions/types';
import _ from 'lodash'

const INITIAL_STATE = { question: {}, questions: [], message: '', error: '' };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_QUESTIONS:
      var colours = ["#FFFEEE", "#F4F2FF", "#FFFAF3", "#FFF5E7"]
      _.each(action.payload.questions, function(q){
        q.color = colours[Math.round(Math.random()*4)]
      })
      return { ...state, questions: action.payload.questions, message:'', error: '' };
    case FETCH_QUESTION:
      return { ...state, question: action.payload.question, message: '', error: '' };
    case CREATE_QUESTION:
      return { ...state, message:action.payload.message, error: '' };
    case INIT_MESSAGE_QUESTION:
      return { ...state, message:'', error: '' };
    case UPDATE_QUESTION:
      return { ...state, message:action.payload.message, error: '' };
    case DELETE_QUESTION:
      return { ...state, question:{}, message: action.payload.message, error: '' };
    case QUESTION_ERROR:
      return { ...state, error: action.payload };
  }

  return state;
}
