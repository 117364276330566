import React, { Component } from 'react';
import { Link, browserHistory, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { updateQuestion, fetchQuestion, deleteQuestion, initMessage } from '../../actions/question';
import ContainerTranslation from "../partials/containerTranslation";
import pdf from './../../assets/images/kelvi_response_guide.pdf'

class QuestionView extends Component {

  constructor(props) {
    super(props);
    const { params, updateQuestion, fetchQuestion, deleteQuestion, initMessage } = this.props;
    var question = window.location.pathname.split('/')[window.location.pathname.split('/').length-1]
    this.state = {
      answer:"",
      question:"",
      isPublic:false,
      review:false,
      deleteModale:false,
      send:false
    };
    fetchQuestion(question);
  }

  componentDidMount() {
    if(this.props.question){
      this.setState({
        answer:this.props.question.answer,
        question:this.props.question.question,
        isPublic:this.props.question.isPublic,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.message && this.props.message === "Question successfully updated!" && this.state.send === false){
      this.setState({
        send:true
      })
    }
    if(this.props.message && this.props.message === "Question successfully removed!"){
      this.props.initMessage()
      this.props.history.push('/dashboard');
    }
    if(this.props.question && !prevProps.question || this.props.question._id !== prevProps.question._id){
      this.setState({
        answer:this.props.question.answer,
        question:this.props.question.question,
        isPublic:this.props.question.isPublic,
      })
    }
  }

  handleAnswerChange(e){
    this.setState({
      answer:e.currentTarget.value
    })
  }

  handleQuestionChange(e){
    this.setState({
      question:e.currentTarget.value
    })
  }

  goToReview(e){
    this.setState({
      review:true
    })
  }

  toggleWallPost(e, value){
    this.setState({
      isPublic:value
    })
  }

  toggleDeleteModale(e){
    var _this = this
    this.setState({
      deleteModale:!_this.state.deleteModale
    })
  }

  deleteQuestion(e){
    this.props.deleteQuestion(this.props.question._id)
  }

  backFromReview(e){
    this.setState({
      review:false
    })
  }

  backFromThanks(e){
    this.props.initMessage()
    this.props.history.push('/dashboard');
  }

  sendAnswer(e){
    var _this= this
    e.preventDefault()
    this.props.updateQuestion({
      answer:_this.state.answer,
      question:_this.state.question,
      isPublic:_this.state.isPublic,
    }, this.props.question._id)
  }

  render() {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return(
      <div className="page-container" style={{paddingTop:20}}>


        {this.state.send ? (
          <div style={{marginLeft:150}}>
            <h1 style={{display:'inline-block', marginBottom:0}}><ContainerTranslation id={"dashboard.thanks"}/></h1>
            <p style={{maxWidth: '500px', fontSize: '18px', color: '#6C7684'}}><ContainerTranslation id={"dashboard.thanksText"}/></p>
            <a className="btn" onClick={this.backFromThanks.bind(this)} style={{marginTop: 20, display: 'block', textAlign: 'center', width: 200}}>
              <ContainerTranslation id={"dashboard.back"}/>
            </a>
          </div>
        ):(
          null
        )}


        {this.state.review && !this.state.send ? (
          <div style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '1004px'}}>
            <a onClick={this.backFromReview.bind(this)} style={{marginTop: 38, marginRight: 20, width: 30, display:'inline-block',verticalAlign: 'top', fontSize: '30px'}}>
              <i className="fas fa-arrow-left"></i>
            </a>
            <h1 style={{display:'inline-block', marginBottom:0}}>Review</h1>
            <p style={{marginLeft: 50, maxWidth: '500px', fontSize: '18px', color: '#6C7684'}}><ContainerTranslation id={"dashboard.reviewText"}/></p>
            <p style={{marginLeft: 50}}><ContainerTranslation id={"dashboard.question"}/></p>
            <textarea style={{marginLeft: 50, maxWidth:720, height:30}} value={this.state.question} onChange={ (e) => this.handleQuestionChange(e) }>
            </textarea>
            <p style={{marginLeft: 50}}><ContainerTranslation id={"dashboard.answer"}/></p>
            <textarea disabled={true} style={{marginLeft: 50, maxWidth:720}} value={this.state.answer} onChange={ (e) => this.handleAnswerChange(e) }>
            </textarea>
            <a className="btn" onClick={this.sendAnswer.bind(this)} style={{marginLeft: 50, marginTop: 20, display: 'block', textAlign: 'center', width: 200}}>
              <ContainerTranslation id={"global.submit"}/>
            </a>
          </div>
        ):(
          null
        )}


        {!this.state.review && !this.state.send ? (
          <div style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: '954px'}}>
            <h1 style={{marginBottom:0}}>Question : </h1>
            {this.props.question ? (
              <div>
                <h2 style={{marginTop:0}}>{this.props.question.question}</h2>
                <div style={{width:600, display:'inline-block'}}>
                  <p><ContainerTranslation id={"dashboard.answer"}/></p>
                  <a className="btn-mic" style={{position: 'absolute', marginLeft: '545px'}}><div><i className="fas fa-microphone"></i></div></a>
                  <textarea value={this.state.answer} onChange={ (e) => this.handleAnswerChange(e) }>
                  </textarea>
                  <div>
                    <a onClick={ (e) => this.toggleDeleteModale(e) } style={{float:'right', color:"#6C7684", fontSize:'18px'}}><i className="fas fa-trash"></i></a>
                    <p><ContainerTranslation id={"dashboard.responseGuide"}/> <a href={pdf} target="_blank"><ContainerTranslation id={"dashboard.responseGuideLink"}/></a></p>
                    {this.state.isPublic === true ? (
                      <a key={new Date().getTime()+"isPublic"} onClick={ (e) => this.toggleWallPost(e, false) } style={{fontSize:'24px', color:'#284390', textAlign:'center'}}><i className="fas fa-check-square"></i> <span style={{fontSize:'14px', marginTop: 6, verticalAlign: 'top' ,display: 'inline-block'}}><ContainerTranslation id={"dashboard.line-header-3"}/></span></a>
                    ):(
                      <a key={new Date().getTime()+"isPublic"} onClick={ (e) => this.toggleWallPost(e, true) } style={{fontSize:'24px', color:'#E1E7EC', textAlign:'center'}}><i className="far fa-square"></i> <span style={{fontSize:'14px', marginTop: 6, verticalAlign: 'top' ,display: 'inline-block'}}><ContainerTranslation id={"dashboard.line-header-3"}/></span></a>
                    )}
                    {this.state.answer ? (
                      <a className="btn" onClick={this.goToReview.bind(this)} style={{marginTop: 20, display: 'block', textAlign: 'center', width: 200}}>
                        <ContainerTranslation id={"global.next"}/>
                      </a>
                    ):(
                      <a className="btn" style={{marginTop: 20, display: 'block', textAlign: 'center', width: 200, cursor:'not-allowed', opacity:0.3}}>
                        <ContainerTranslation id={"global.next"}/>
                      </a>
                    )}
                  </div>
                </div>
                <div style={{display:'inline-block', verticalAlign:'top', marginLeft: 80}}>
                  <p style={{fontSize:"14px", color:"#6C7684", marginBottom:0}}><ContainerTranslation id={"dashboard.email"}/></p>
                  <p style={{fontSize:"20px", color:"#212934", marginTop:0}}>{this.props.question.author ? this.props.question.author.email:"Not specified"}</p>
                  <p style={{fontSize:"14px", color:"#6C7684", marginBottom:0}}><ContainerTranslation id={"dashboard.phone"}/></p>
                  <p style={{fontSize:"20px", color:"#212934", marginTop:0}}>{this.props.question.author ? this.props.question.author.phone:"Not specified"}</p>
                  <p style={{fontSize:"14px", color:"#6C7684", marginBottom:0}}><ContainerTranslation id={"dashboard.date"}/></p>
                  <p style={{fontSize:"20px", color:"#212934", marginTop:0}}>{new Date(this.props.question.createdAt).toLocaleDateString('en-EN', options)}</p>
                </div>
              </div>
            ):(
              <p>No question found</p>
            )}
          </div>
        ):(
          null
        )}

        {this.state.deleteModale ? (
          <div className="modal">
            <div className="modal-container">
              <div className="modal-content" style={{paddingBottom:60}}>
                <h1 style={{display:'inline-block', marginBottom:0}}><ContainerTranslation id={"dashboard.deleteTitle"}/></h1>
                <p style={{maxWidth: '500px', fontSize: '18px', color: '#6C7684'}}><ContainerTranslation id={"dashboard.deleteText"}/></p>
                <a className="btn btn-secondary" onClick={ (e) => this.toggleDeleteModale(e) } style={{marginTop: 20, marginRight: 10, display: 'inline-block', textAlign: 'center', width: 200}}>
                  <ContainerTranslation id={"dashboard.deleteCancel"}/>
                </a>
                <a className="btn" onClick={ (e) => this.deleteQuestion(e) } style={{marginTop: 20, marginLeft: 10, display: 'inline-block', textAlign: 'center', width: 200}}>
                  <ContainerTranslation id={"dashboard.deleteOk"}/>
                </a>
              </div>
            </div>
          </div>
        ):(
          null
        )}

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    question: state.question.question,
    message: state.question.message
  };
}

export default withRouter(connect(mapStateToProps, { fetchQuestion, updateQuestion, deleteQuestion, initMessage })(QuestionView));
