import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';
import { fetchProfile } from '../../actions/index';
import ReactTooltip from 'react-tooltip'
import ContainerTranslation from "../partials/containerTranslation";
import logo from '../../assets/images/logo.svg';
import { fetchLanguages } from '../../actions/language';
import LanguageToggle from "./language-toggle";

class HeaderTemplate extends Component {

  constructor(props) {
    super(props);
    const { params, fetchLanguages } = this.props;
    if(this.props.profile && !this.props.profile._id){
      if(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))){
        const userId = JSON.parse(localStorage.getItem("user"))._id
        this.props.fetchProfile(userId);
      }
    }
    fetchLanguages()
    this.state = {
      displayLanguages:false,
      displaySubmenu:false
    };
  }

  componentDidMount(){
    var _this = this
    document.addEventListener('click', function(event) {
      if (event.target.className && event.target.className.toString().indexOf('language') === -1 && event.target.className.toString().indexOf('SVGAnimatedString') === -1) {
        _this.setState({
          displayLanguages:false,
          displaySubmenu:false
        })
      }
    });
  }

  componentDidUpdate(prevProps){
  }

  toggleLanguages(){
    var _this = this
    this.setState({
      displayLanguages:!_this.state.displayLanguages,
      displaySubmenu:false
    })
  }

  toggleSubmenu(){
    var _this = this
    this.setState({
      displaySubmenu:!_this.state.displaySubmenu,
      displayLanguages:false
    })
  }

  isRole(roleToCheck) {
    const userRole = this.props.profile.role
    if (userRole == roleToCheck) {
      return true;
    }
    return false;
  }

  renderLinks() {
    var menu = []
    var language = ''
    if(this.props.activeLanguage){
      language = this.props.activeLanguage.name
    }
    var languageCode = ''
    if(this.props.activeLanguage){
      languageCode = this.props.activeLanguage.code
    }
    if(this.isRole('Admin')){
      menu.push(<li key={`${1}header`} className={(this.props.location.startsWith("/user") ? 'active hideOnMobile' : 'hideOnMobile')}>
        <Link to="/user"><span className="hideOnMobile"><ContainerTranslation id={"header.user"}/></span></Link>
      </li>)
    }
    if(!this.props.authenticated){
      menu.push(<li key={`${2}header`} className={(this.props.location.startsWith("/about") || this.props.location === "/" ? 'active hideOnMobile' : 'hideOnMobile')}>
        <Link to="/about"><span className=""><ContainerTranslation id={"header.about"}/></span></Link>
      </li>)
      menu.push(<li key={`${3}header`} className={(this.props.location.startsWith("/login") || this.props.location === "/" ? 'active' : '')}>
        <Link to="/login"><span className="circled" style={{background:'rgba(255,255,255,0.1)'}}><ContainerTranslation id={"header.login"}/></span></Link>
      </li>)
      menu.push(<li key={`${4}header`}>
        <a onClick={ (e) => this.toggleLanguages(e) }><span className="language"><i className="fas fa-globe"></i>{language}<i className="fas fa-angle-down caret"></i></span></a>
      </li>)
    }else{
      var name = ""
      if(this.props.profile && this.props.profile.profile){
        name = this.props.profile.profile.firstName+" "+this.props.profile.profile.lastName
      }
      menu.push(<li key={`${2}header`}>
        <a onClick={ (e) => this.toggleLanguages(e) } style={{textTransform: 'capitalize'}}>{languageCode}</a>
      </li>)
      menu.push(<li key={`${3}header`} className={(this.props.location.startsWith("/help") || this.props.location === "/" ? 'active' : '')}>
        <a href="mailto:contact@kelvi.org?subject=Need help!"><span className="hideOnMobile"><ContainerTranslation id={"header.help"}/></span></a>
      </li>)
      menu.push(<li key={`${4}header`} className={(this.props.location.startsWith("/profile") ? 'active' : '')}>
        <Link to="/dashboard"><span className="hideOnMobile">{name}</span></Link>
      </li>)
      menu.push(<li key={`${5}header`} className={(this.props.location.startsWith("/profile") ? 'active' : '')}>
        <a onClick={ (e) => this.toggleSubmenu(e) }><i className="fas fa-angle-down"></i></a>
      </li>)

    }
    return menu
  }

  render() {
    var cl = "header"
    if(this.props.location.startsWith("/login") ||
       this.props.location.startsWith("/logout") ||
       this.props.location.startsWith("/forgot-password") ||
       this.props.location.startsWith("/reset-password") ||
       this.props.location.startsWith("/dashboard")){
      cl += " yellow"
    }

    return (
      <div>
        <header className={cl}>
          <Link className="navbar-brand" to="/">
            <img src={logo}/>
          </Link>
          <nav>
            <ul className="navbar">
              {this.renderLinks()}
            </ul>
          </nav>
          <ReactTooltip className='tooltip' place="left" id="logout" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
        </header>
        {this.state.displayLanguages ? (
          <div className="header-languages">
            <LanguageToggle/>
          </div>
        ):(
          null
        )}
        {this.state.displaySubmenu ? (
          <div className="header-submenu">
            <div className="submenu">
              <Link to="/profile" style={{fontSize:'18px', color:'#000000', lineHeight:'36px', textDecoration:'none'}}><ContainerTranslation id={"header.account"}/></Link>
            </div>
            <div className="submenu" style={{borderTop:'solid 1px #E1E7EC'}}>
              <Link to="/logout" style={{fontSize:'18px', color:'#000000', lineHeight:'36px', textDecoration:'none'}}><ContainerTranslation id={"header.logout"}/></Link>
            </div>
          </div>
        ):(
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {

  return {
    authenticated: state.auth.authenticated,
    languages: state.language.languages,
    profile: state.user.profile
  };
}

export default withLocalize(connect(mapStateToProps, {fetchProfile, fetchLanguages})(HeaderTemplate));
