import axios from 'axios';
import { createBrowserHistory } from 'history';
import { API_URL, CLIENT_ROOT_URL, errorHandler } from './index';
import { AUTH_USER, AUTH_ERROR, REGISTER_USER, UNAUTH_USER, FORGOT_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST, PROTECTED_TEST, UPDATE_USER, USER_ERROR } from './types';

//= ===============================
// Authentication actions
//= ===============================

// TO-DO: Add expiration to cookie
export function loginUser({ email, password }) {
  return function (dispatch) {
    axios.post(`${API_URL}/auth/login`, { email, password })
    .then((response) => {
      const token = localStorage.getItem("token")
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      dispatch({ type: AUTH_USER });
      //window.location.href = `${CLIENT_ROOT_URL}`;
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, AUTH_ERROR);
    });
  };
}

export function registerUser(user) {
  return function (dispatch) {
    axios.post(`${API_URL}/auth/register`, user)
    .then((response) => {
      dispatch({ type: REGISTER_USER, payload:response.data });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, AUTH_ERROR);
    });
  };
}

export function editProfile(uid, { email, firstName, lastName, phone, password, language }) {
  return function (dispatch) {
    let headers = { headers: { Authorization: localStorage.getItem("token") } };
    let data = { email, firstName, lastName, phone, password, language };
    //axios.put(`${API_URL}/user/edit/${uid}`, data, headers)
    axios.put(`${API_URL}/profile`, data, headers)
      .then((response) => {
        dispatch({ type: UPDATE_USER, payload:response.data });
      })
      .catch((error) => {
        errorHandler(dispatch, error.response, USER_ERROR);
      });
  };
}

export function logoutUser(error) {
  return function (dispatch) {
    dispatch({ type: UNAUTH_USER, payload: error || '' });
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    window.location.href = `${CLIENT_ROOT_URL}/login`;
  };
}

export function getForgotPasswordToken({ email }) {
  return function (dispatch) {
    axios.post(`${API_URL}/auth/forgot-password`, { email })
    .then((response) => {
      dispatch({
        type: FORGOT_PASSWORD_REQUEST,
        payload: response.data,
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, AUTH_ERROR);
    });
  };
}

export function resetPassword(token, { password }) {
  return function (dispatch) {
    axios.post(`${API_URL}/auth/reset-password/${token}`, { password })
    .then((response) => {
      dispatch({
        type: RESET_PASSWORD_REQUEST,
        payload: response.data.message,
      });
      // Redirect to login page on successful password reset
      createBrowserHistory.push('/login');
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, AUTH_ERROR);
    });
  };
}

export function protectedTest() {
  return function (dispatch) {
    axios.get(`${API_URL}/protected`, {
      headers: { Authorization: localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: PROTECTED_TEST,
        payload: response.data.content,
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, AUTH_ERROR);
    });
  };
}
