import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/auth';

class Logout extends Component {
  componentDidMount() {
    var _this = this
    setTimeout(function(){
      _this.props.logoutUser();
    }, 2000);
  }

  render() {
    var classHeader = "header-login close"
    var classForm = "form-login close"
    var classLine = "line-login close"

    return (
      <div className="page-login" style={{justifyContent:'center', alignItems:'center'}}>
        <div className="line-login-back"></div>
        <div className={classLine}></div>
        <div className={classHeader}>
        </div>
        <div className={classForm}>
          <h1>See you next time !</h1>
        </div>
      </div>
    );
  }
}

export default connect(null, actions)(Logout);
