import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { resetPassword } from '../../actions/auth';

const form = reduxForm({
  form: 'resetPassword',
  validate,
});

const renderField = field => (
  <div>
    {field.meta.touched && field.meta.error && <div className="error">{field.meta.error}</div>}
    <input className="form-control" type={field.type} placeholder={field.placeholder} {...field.input}/>
  </div>
);

function validate(formProps) {
  const errors = {};
  var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");

  if (!formProps.password) {
    errors.password = 'Please enter a new password';
  }

  if (!formProps.passwordConfirm) {
    errors.passwordConfirm = 'Please confirm new password';
  }

  if (formProps.password !== formProps.passwordConfirm) {
    errors.passwordConfirm = 'Passwords must match';
  }

  if(!strongRegex.test(formProps.passwordConfirm)) {
    errors.passwordConfirm = 'Please enter a password with all requirement';
  }

  if(!strongRegex.test(formProps.password)) {
    errors.password = 'Please enter a password with all requirement';
  }

  return errors;
}

class ResetPassword extends Component {
  static contextTypes = {
    router: PropTypes.object,
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.context.router.push('/');
    }
  }

  componentDidUpdate(nextProps) {
    if (nextProps.authenticated) {
      this.context.router.push('/');
    }
  }

  handleFormSubmit({ password }) {
    var resetToken = window.location.pathname.split('/')[window.location.pathname.split('/').length-1]
    this.props.resetPassword(resetToken, { password });
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="message error">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    } else if (this.props.message) {
      return (
        <div className="message">
          <strong>Success!</strong> {this.props.message}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="page">
        <div className="page-login">
          <div className="col-login">
            <div className="header-login">
              <h1 style={{marginBottom: 0}}>Reset your password</h1>
            </div>
            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} className="form-login" style={{top:0, animationDelay: 0}}>
              {this.renderAlert()}
              <p style={{fontStyle: 'italic', color: '#999', marginTop: 0, maxWidth:540}}>Password must contain one uppercase, one lowercase, one number and be 6 characters long</p>
              <div className="row" style={{textAlign: 'left'}}>
                <label className="" htmlFor="password">New Password</label>
                <Field name="password" component={renderField} type="password" placeholder="New password ..."/>
              </div>
              <div className="row" style={{textAlign: 'left'}}>
                <label className="" htmlFor="passwordConfirm">Confirm Password</label>
                <Field name="passwordConfirm" component={renderField} type="password" placeholder="Confirm your new password ..."/>
              </div>
              <button type="submit" className="btn btn-submit">Change Password</button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.auth.error, message: state.auth.resetMessage };
}

export default connect(mapStateToProps, { resetPassword })(form(ResetPassword));
