import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';
import ContainerTranslation from "../partials/containerTranslation";
import List from "../partials/Menu/List";
import { updateQuestion, fetchQuestions } from '../../actions/question';
import _ from 'lodash'
import Select from 'react-select';
import Questions from '../question/questions';

class DashboardView extends Component {

  constructor(props) {
    super(props);
    const { params, updateQuestion, fetchQuestions} = this.props;
    this.state = {
      selectedMenu:null,
      filter:false
    };
    fetchQuestions()
  }

  componentDidUpdate(prevProps){
    const _this = this

    if((this.props.activeLanguage && !prevProps.activeLanguage) || this.props.activeLanguage !== prevProps.activeLanguage || !this.state.language){
      var foundLanguage = _.find(_this.props.languages, function(l){return l.code ===_this.props.activeLanguage.code})
      if(foundLanguage){
        this.setState({
          language:foundLanguage._id
        })
      }
    }
  }

  toggleFilter(e){
    var _this = this
    this.setState({
      filter:!_this.state.filter
    })
  }

  toggleWallPost(e, value, questionId){
    e.preventDefault()
    this.props.updateQuestion({
      isPublic:value
    }, questionId)
  }

  selectMenu(d){
    this.setState({
      selectedMenu:d
    })
  }

  getStatus(d){
    if(d.answer){
      return 'Responded'
    }else{
      return 'Respond'
    }
  }

  getStatusClass(d){
    if(!d.answer){
      return 'question-status'
    }else{
      return 'question-status disabled'
    }
  }

  render() {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const _this = this
    let questions = []
    if(this.props.questions){
      questions = _this.props.questions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      if(this.state.selectedMenu === 'menu1'){
        questions = _.filter(questions, function(q){
          return !q.answer || q.answer === ''
        })
      }
      if(this.state.selectedMenu === 'menu2'){
        questions = _.filter(questions, function(q){
          return q.answer && q.answer !== ''
        })
      }
      if(this.state.selectedMenu === 'menu3'){
        questions = _.filter(questions, function(q){
          return !q.isPublic
        })
      }
    }

    if(this.state.filter){
      questions = _.sortBy(questions, [function(q) { return q.language.name; }]);
    }

    var tabList = [
      {
        _id:"menu"+0,
        nameTranslate:"dashboard.menu1"
      },
      {
        _id:"menu"+1,
        nameTranslate:"dashboard.menu2"
      },
      {
        _id:"menu"+2,
        nameTranslate:"dashboard.menu3"
      },
      {
        _id:"menu"+3,
        nameTranslate:"dashboard.menu4"
      }
    ]

    const status = 'pending'

    return (
      <div>
        <List
          style={{width:200}}
          data = {tabList}
          theme = "light"
          selectedItem={this.state.selectedMenu}
          selectItem={this.selectMenu.bind(this)}
        />
        {questions.length ? (
          <div style={{textAlign: 'left', display: 'inline-block', width: 'calc(100% - 310px)'}}>
            <div className="container dashboard-question-header">
              <div className="col-question" style={{width:180, textAlign:'left'}}>
                <a style={{color:"#212934"}} onClick={ (e) => this.toggleFilter(e) }>
                  {this.state.filter ? (
                    <span style={{marginRight:10}}><ContainerTranslation id={"dashboard.line-header-12"}/></span>
                  ) : (
                    <span style={{marginRight:10}}><ContainerTranslation id={"dashboard.line-header-1"}/></span>
                  )}
                  <i className="fas fa-angle-down caret"></i></a>
              </div>
              <div className="col-question" style={{width:'calc(100% - 440px)', textAlign:'left'}}>
                <span><ContainerTranslation id={"dashboard.line-header-2"}/></span>
              </div>
              <div className="col-question" style={{width:100}}>
                <span><ContainerTranslation id={"dashboard.line-header-3"}/></span>
              </div>
              <div className="col-question" style={{width:160}}>
                <span><ContainerTranslation id={"dashboard.line-header-4"}/></span>
              </div>
            </div>
            {questions.map(d =>
              <Link to={"/dashboard/"+d._id} className="container dashboard-question" key={d._id}>
                <div className="col-question" style={{width:180}}>
                  <span style={{fontSize:'18px', display:'block'}}>{d.language ? d.language.name : 'N/A'}</span>
                  <span style={{fontSize:'18px', opacity:0.3}}>{new Date(d.createdAt).toLocaleDateString('en-EN', options)}</span>
                </div>
                <div className="col-question" style={{width:'calc(100% - 440px)'}}>
                  <span style={{fontSize:'18px', fontWeight:600}}>{d.question}</span>
                </div>
                <div className="col-question" style={{width:100}}>
                  {d.isPublic === true ? (
                    <a key={new Date().getTime()+"isPublic"} onClick={ (e) => this.toggleWallPost(e, false, d._id) } style={{fontSize:'24px', color:'#284390', textAlign:'center', cursor:'pointer'}}><i className="fas fa-check-square"></i></a>
                  ):(
                    <a key={new Date().getTime()+"isPublic"} onClick={ (e) => this.toggleWallPost(e, true, d._id) } style={{fontSize:'24px', color:'#E1E7EC', textAlign:'center', cursor:'pointer'}}><i className="far fa-square"></i></a>
                  )}
                </div>
                <div className="col-question" style={{width:160}}>
                  <span className={this.getStatusClass(d)}>{this.getStatus(d)}</span>
                </div>
              </Link>
            )}
          </div>
        ) : (
          null
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    languages: state.language.languages,
    questions: state.question.questions,
    message: state.question.message
  };
}

export default withLocalize(connect(mapStateToProps, { updateQuestion, fetchQuestions })(DashboardView));
