import React, { Component } from 'react';
import Item from './Item';

class List extends Component {

  selectItem(id, flag){
    this.props.selectItem(id, flag)
  }

  editItem(id){
    this.props.editItem(id)
  }

  deleteItem(id){
    this.props.deleteItem(id)
  }

  render() {
    var customClass = "list"

    return (
      <div className={customClass}>
        {this.props.data.map(d => <Item
          key={d._id}
          name={d.name}
          role={d.role}
          nameTranslate={d.nameTranslate}
          id={d._id}
          equipment={d.equipment}
          showAvailable={this.props.showAvailable}
          available={d.available}
          withTools={this.props.withTools}
          selectItem={this.selectItem.bind(this)}
          editItem={this.editItem.bind(this)}
          deleteItem={this.deleteItem.bind(this)}
        />)}
      </div>
    );

  }
}

export default List;
