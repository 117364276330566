import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth_reducer';
import userReducer from './user_reducer';
import questionReducer from './question_reducer';
import languageReducer from './language_reducer';

const rootReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  user: userReducer,
  question: questionReducer,
  language: languageReducer
});

export default rootReducer;
