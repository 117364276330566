import { getData, postData, putData, deleteData } from './index';
import { FETCH_QUESTIONS, FETCH_QUESTION, INIT_MESSAGE_QUESTION, CREATE_QUESTION, UPDATE_QUESTION, DELETE_QUESTION, QUESTION_ERROR } from './types';

//= ===============================
// Question actions
//= ===============================

export function createQuestion(question) {
  const data = question;
  const url = '/question';
  return dispatch => postData(CREATE_QUESTION, QUESTION_ERROR, true, url, dispatch, data);
}

export function fetchQuestions() {
  const url = '/question';
  return dispatch => getData(FETCH_QUESTIONS, QUESTION_ERROR, true, url, dispatch);
}

export function fetchPublicQuestions() {
  const url = '/question/public';
  return dispatch => getData(FETCH_QUESTIONS, QUESTION_ERROR, true, url, dispatch);
}

export function fetchLastQuestion() {
  const url = '/question/last';
  return dispatch => getData(FETCH_QUESTION, QUESTION_ERROR, true, url, dispatch);
}

export function fetchQuestion(questionId) {
  const url = '/question/'+questionId;
  return dispatch => getData(FETCH_QUESTION, QUESTION_ERROR, true, url, dispatch);
}

export function updateQuestion(question, questionId) {
  const url = '/question/'+questionId
  let data = question
  return dispatch => putData(UPDATE_QUESTION, QUESTION_ERROR, true, url, dispatch, data);
}

export function deleteQuestion(questionId) {
  const url = '/question/'+questionId;
  return dispatch => deleteData(DELETE_QUESTION, QUESTION_ERROR, true, url, dispatch);
}

export function initMessage() {
  return dispatch => dispatch({
    type: INIT_MESSAGE_QUESTION,
    payload: null
  });
}
