import React, { Component } from 'react';
import { Translate } from "react-localize-redux";


class ContainerTranslation extends Component {

  render(){
    return <Translate >
      {
        ({ translate }) => {
          if(translate(this.props.id) === ""){
             return translate(this.props.id, null, { language: 'en' })
          }else{
            return translate(this.props.id)
          }
        }
      }
    </Translate>
  }

}

export default ContainerTranslation;
