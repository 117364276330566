import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchProfile } from '../../actions/index';
import { Link } from 'react-router-dom';
import ContainerTranslation from "../partials/containerTranslation";
import LanguageToggle from "./language-toggle";
import { editProfile } from '../../actions/auth';
import _ from 'lodash';

class Profile extends Component {

  constructor(props) {
    super(props);
    const { params, editProfile} = this.props;
    this.state = {
      userFirstName:"",
      userLastName:"",
      userPassword:"",
      userPhone:"",
      languages:[],
      message: ""
    };
  }

  componentDidUpdate(prevProps){
    const _this = this
    if(this.props.message && this.props.message !== prevProps.message){
      if(this.props.message === "Profile successfully updated." && this.state.message !== "Profil à jour !"){
        this.setState({
          message:"Profil à jour !"
        })
      }
    }
    if(this.props.profile && this.props.profile.profile && this.props.profile.profile.firstName !== "" && this.state.userFirstName === ""){
      this.setState({
        userFirstName:_this.props.profile.profile.firstName
      })
    }
    if(this.props.profile && this.props.profile.profile && this.props.profile.profile.lastName !== "" && this.state.userLastName === ""){
      this.setState({
        userLastName:_this.props.profile.profile.lastName
      })
    }
    if(this.props.profile && this.props.profile.profile && this.props.profile.profile.phone !== "" && this.state.userPhone === ""){
      this.setState({
        userPhone:_this.props.profile.profile.phone
      })
    }
    if(this.props.profile && this.props.profile.language && this.props.profile.language.length >= 1 && this.state.languages.length === 0){
      this.setState({
        languages:_this.props.profile.language
      })
    }
  }

  componentDidMount() {
    // Fetch user data prior to component mounting
    const profileId = JSON.parse(localStorage.getItem("user"))._id
    this.props.fetchProfile(profileId);
  }

  toggleLanguage(id){
    var languages = this.state.languages
    var foundLanguage = _.find(languages, function(l){
      return l === id
    });
    if(!foundLanguage){
      languages.push(id)
    }else{
      _.remove(languages, function(l){
        return l === id
      })
    }
    this.setState({
      languages:languages
    })
  }

  handleFirstNameChange(e){
    this.setState({
      userFirstName:e.currentTarget.value
    })
  }

  handleLastNameChange(e){
    this.setState({
      userLastName:e.currentTarget.value
    })
  }

  handlePasswordChange(e){
    this.setState({
      userPassword:e.currentTarget.value
    })
  }

  handlePhoneChange(e){
    this.setState({
      userPhone:e.currentTarget.value
    })
  }

  updateProfile(){
    const _this = this
    this.props.editProfile(_this.props.profile._id, {
      email:_this.props.profile.email,
      firstName:_this.state.userFirstName,
      lastName:_this.state.userLastName,
      phone:_this.state.userPhone,
      password:_this.state.userPassword,
      language: _this.state.languages
    })
  }

  isRole(roleToCheck) {
    const userRole = this.props.profile.role
    if (userRole == roleToCheck) {
      return true;
    }
    return false;
  }

  render() {
    console.log(this.state.languages)
    return (
      <div>
        <div className="page-container">
          <h1 className="page-title" style={{fontSize: 42, textAlign: 'center', display: 'block', marginLeft: 'auto', marginRight: 'auto'}}><ContainerTranslation id={"profile.information"}/></h1>

          <div className="form-login" style={{top:0, animationDelay: 0, maxWidth:600, marginLeft:'auto', marginRight:'auto'}}>
            <div className="container col">
                <div style={{top:0, animationDelay: 0, maxWidth:600, marginLeft:'auto', marginRight:'auto'}}>
                  <div className="row">
                    <div className="col half">
                      <label className="" htmlFor="firstName" style={{color:"#333333"}}>First Name</label>
                      <input name="firstName" id="firstName" value={this.state.userFirstName} type="text" placeholder="Your first name ..." onChange={ (e) => this.handleFirstNameChange(e) }/>
                    </div>
                    <div className="col half">
                      <label className="" htmlFor="lastName" style={{color:"#333333"}}>Last Name</label>
                      <input name="lastName" id="lastName" value={this.state.userLastName} type="text"  placeholder="Your last name ..." onChange={ (e) => this.handleLastNameChange(e) }/>
                    </div>
                  </div>
                  <label style={{marginTop: '16px'}} htmlFor="userPhone" style={{color:"#333333"}}>Phone</label>
                  <input name="userPhone" value={this.state.userPhone} type="tel" placeholder="Your phone number..." onChange={ (e) => this.handlePhoneChange(e) }/>
                  <label style={{marginTop: '16px'}} htmlFor="userPassword">
                    <ContainerTranslation id={"user.password"}/>
                  </label>
                  <input name="userPassword" value={this.state.userPassword} type="password" placeholder="Password..." onChange={ (e) => this.handlePasswordChange(e) }/>
                  <h2 className="page-title" style={{fontSize:"18px", textAlign: 'left', display: 'block', marginTop:30, marginBottom: 20, color:"#212934"}}>
                    <ContainerTranslation id={"register.subtitle2"}/>
                  </h2>
                  {this.props.languages.map((d,i) =>
                    <div className="language" key={d.code} style={{display:'inline-block', width: '33.33%'}}>
                      {this.state.languages.indexOf(d._id) != -1 ? (
                        <a key={new Date().getTime()+d._id} style={{fontSize:'14px', color:'#000000', lineHeight:'36px'}} onClick={ (e) => this.toggleLanguage(d._id) }>
                          <span style={{fontSize:'24px', color:'#284390', cursor:'pointer', marginRight:10, verticalAlign: 'top'}}><i className="fas fa-check-square"></i></span>{d.name}
                        </a>
                      ):(
                        <a key={new Date().getTime()+d._id} style={{fontSize:'14px', color:'#000000', lineHeight:'36px'}} onClick={ (e) => this.toggleLanguage(d._id) }>
                          <span style={{fontSize:'24px', color:'#E1E7EC', cursor:'pointer', marginRight:10, verticalAlign: 'top'}}><i className="far fa-square"></i></span>{d.name}
                        </a>
                      )}

                    </div>
                  )}
                  <a className="btn btn-submit" onClick={this.updateProfile.bind(this)} style={{marginTop: '50px', marginLeft: 'auto', marginRight: 'auto', maxWidth: 350, textAlign:'center'}}>
                    <ContainerTranslation id={"global.confirm"}/>
                  </a>
                  {this.state.message ? (
                    <p style={{color: '#80c92b', textAlign: 'center'}}>{this.state.message}</p>
                  ) : (
                    null
                  )}
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.user.profile,
    message: state.user.message,
    languages: state.language.languages
  };
}

export default connect(mapStateToProps, { fetchProfile, editProfile })(Profile);
