import axios from 'axios';
import { logoutUser } from './auth';
import { STATIC_ERROR, FETCH_PROFILE, USER_ERROR } from './types';

//export const API_URL = 'http://localhost:50001/api';
//export const CLIENT_ROOT_URL = 'http://localhost:3000';
export const API_URL = 'https://api.kelvi.fr/api';
export const CLIENT_ROOT_URL = 'https://app.kelvi.fr';


//= ===============================
// Utility actions
//= ===============================

export function fetchProfile(uid) {
  return function (dispatch) {
    axios.get(`${API_URL}/profile`, {
      headers: { Authorization: localStorage.getItem("token") },
    })
    .then((response) => {
      dispatch({
        type: FETCH_PROFILE,
        payload: response.data.user,
      });
    })
    .catch((error) => {
      errorHandler(dispatch, error.response, USER_ERROR);
    });
  };
}

export function errorHandler(dispatch, error, type) {
  console.log('Error type: ', type);
  console.log(error);

  let errorMessage = error && error.response ? error.response.data : error;

   // NOT AUTHENTICATED ERROR
   if (error.status === 401 || error.response && error.response.status === 401) {
     errorMessage = 'You are not authorized to do this.';
     return dispatch(logoutUser(errorMessage));
   }

  dispatch({
    type,
    payload: errorMessage,
  });
}

// Post Request
export function postData(action, errorType, isAuthReq, url, dispatch, data, file, buffer) {
  const requestUrl = API_URL + url;
  let headers = {}
  if (isAuthReq) {
    headers = { headers: { Authorization: localStorage.getItem("token") } };
  }

  if(file){
    headers.headers['Content-Type'] = 'multipart/form-data'
  }

  if(buffer){
    headers.headers['responseType'] = 'arraybuffer'
  }

  axios.post(requestUrl, data, headers)
  .then((response) => {
    dispatch({
      type: action,
      payload: response.data,
    });
  })
  .catch((error) => {
    errorHandler(dispatch, error.response, errorType);
  });
}

// Get Request
export function getData(action, errorType, isAuthReq, url, dispatch) {
  const requestUrl = API_URL + url;
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: localStorage.getItem("token") } };
  }

  axios.get(requestUrl, headers)
  .then((response) => {
    dispatch({
      type: action,
      payload: response.data,
    });
  })
  .catch((error) => {
    errorHandler(dispatch, error.response, errorType);
  });
}

// Put Request
export function putData(action, errorType, isAuthReq, url, dispatch, data) {
  const requestUrl = API_URL + url;
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: localStorage.getItem("token") } };
  }

  axios.put(requestUrl, data, headers)
  .then((response) => {
    dispatch({
      type: action,
      payload: response.data,
    });
  })
  .catch((error) => {
    errorHandler(dispatch, error.response, errorType);
  });
}

// Delete Request
export function deleteData(action, errorType, isAuthReq, url, dispatch) {
  const requestUrl = API_URL + url;
  let headers = {};

  if (isAuthReq) {
    headers = { headers: { Authorization: localStorage.getItem("token") } };
  }

  axios.delete(requestUrl, headers)
  .then((response) => {
    dispatch({
      type: action,
      payload: response.data,
    });
  })
  .catch((error) => {
    errorHandler(dispatch, error.response, errorType);
  });
}
