import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import ContainerTranslation from "./../containerTranslation";
import { connect } from 'react-redux';

class Item extends Component {

  deleteItem(elm) {
    this.props.deleteItem(this.props.id)
  }

  editItem(elm) {
    this.props.editItem(this.props.id)
  }

  selectItem(elm) {
    this.props.selectItem(this.props.id, !this.props.available)
  }

  isRole(roleToCheck) {
    const userRole = this.props.profile.role
    if (userRole == roleToCheck) {
      return true;
    }
    return false;
  }

  render() {
    var customClass = "list-item"
    if(this.props.selectedItem === this.props.id){
      customClass += " selected"
    }
    if(this.props.withTools === false && this.props.icon === ""){
      customClass += " minus-10"
    }

    return (
      <div className={customClass}>
        <a className="" onClick={this.selectItem.bind(this)}>
          <i className={this.props.icon} style={{verticalAlign:'top', marginTop:'4px'}}></i>
          {this.props.role ? (
            <p className="list-subtitle">{this.props.role}</p>
          ) : (
            null
          )}
          {this.props.nameTranslate ? (
            <p className="list-title"><ContainerTranslation id={this.props.nameTranslate}/></p>
          ) : (
            <p className="list-title">{this.props.name}</p>
          )}
          {this.props.showAvailable && this.props.available === true ? (
            <div className="available">
            </div>
          ) : (
            null
          )}
          {this.props.showAvailable && !this.props.available ? (
            <div className="unavailable">
            </div>
          ) : (
            null
          )}
          <div className="equipment">
            {this.props.equipment && this.props.equipment.indexOf("respirator") !== -1 ? (
              <div style={{display:'inline-block'}}>
                <i className="fas fa-wind" style={{verticalAlign:'top', marginTop:18, marginRight:10}}></i>
              </div>
            ) : (
              null
            )}
            {this.props.equipment && this.props.equipment.indexOf("vni") !== -1 ? (
              <span className="initial" style={{fontWeight: 'bold', marginRight: 10}}>VNI</span>
            ) : (
              null
            )}
          </div>
        </a>
        {this.props.withTools && (this.isRole('Admin') || this.isRole('Owner')) ? (
          <div className="tools hideOnMobile">
            <a onClick={this.editItem.bind(this)} data-for='deleteTeam' data-tip="global.edit"><i className="fas fa-pen" aria-hidden="true"></i></a>
            <a onClick={this.deleteItem.bind(this)} data-for='deleteTeam' data-tip="global.delete"><i className="fa fa-trash" aria-hidden="true"></i></a>
            <ReactTooltip className='tooltip' id='deleteTeam' place="left" type="dark" effect="solid" getContent={(dataTip) => <ContainerTranslation id={`${dataTip}`}/>}/>
          </div>
        ) : (
          null
        )}
      </div>
    );

  }
}

function mapStateToProps(state) {
  return {
    profile: state.user.profile
  };
}

export default connect(mapStateToProps, {})(Item);
