import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { registerUser } from '../../actions/auth';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { fetchLanguages } from '../../actions/language';
import ContainerTranslation from "../partials/containerTranslation";
import Button from "../partials/Button/Button"

const form = reduxForm({
  form: 'register',
  validate,
});

const renderField = field => (
  <div>
    <input className="form-control" type={field.type} placeholder={field.placeholder} {...field.input} value={ undefined }/>
    {field.meta.touched && field.meta.error && <div className="error" style={{textAlign: 'left', marginTop: -12}}>{field.meta.error}</div>}
  </div>
);

function validate(formProps) {
  const errors = {};

  if (!formProps.firstName) {
    errors.firstName = 'Please enter a first name';
  }

  if (!formProps.lastName) {
    errors.lastName = 'Please enter a last name';
  }

  if (!formProps.email) {
    errors.email = 'Please enter an email';
  }
  if (!formProps.phone) {
    errors.phone = 'Please enter a mobile phone';
  }
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formProps.email)){
  }else{
    errors.email = 'Please enter a valid email';
  }

  if (!formProps.password) {
    errors.password = 'Please enter a password';
  }

  if (!formProps.passwordConfirm) {
    errors.passwordConfirm = 'Please confirm new password';
  }

  if (formProps.password !== formProps.passwordConfirm) {
    errors.passwordConfirm = 'Passwords must match';
  }

  var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
  if(!strongRegex.test(formProps.password)) {
    errors.password = 'Please enter a password with all requirement';
  }

  if(!strongRegex.test(formProps.passwordConfirm)) {
    errors.passwordConfirm = 'Please enter a password with all requirement';
  }

  return errors;
}

class Register extends Component {

  constructor(props) {
    super(props);
    const { params, fetchLanguages } = this.props;
    this.state = {
      languages:[],
      isTermsChecked:false,
    };
  }

  handleFormSubmit(formProps) {
    var user = formProps
    user.language = this.state.languages
    this.props.registerUser(user);
  }

  toggleLanguage(id){
    var languages = this.state.languages
    var foundLanguage = _.find(languages, function(l){
      return l === id
    });
    if(!foundLanguage){
      languages.push(id)
    }else{
      _.remove(languages, function(l){
        return l === id
      })
    }
    this.setState({
      languages:languages
    })
  }

  toggleTerms(e, value){
    e.preventDefault()
    this.setState({
      isTermsChecked:value
    })
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="message error">
          <strong>Oops!</strong> {this.props.errorMessage}
        </div>
      );
    } else if (this.props.message === 'user register !') {
      var _this = this
      setTimeout(function(){ _this.props.history.push('/login'); }, 3000);
      return (
        <div className="message">
          <strong>Success!</strong> {this.props.message}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="page-container">
        <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} className="form-login" style={{top:0, animationDelay: 0, maxWidth:600, marginLeft:'auto', marginRight:'auto'}}>
          {this.renderAlert()}
          <h1 className="page-title" style={{fontSize:"42px", textAlign: 'center', display: 'block', marginBottom: 50, marginLeft:'auto', marginRight:'auto'}}>
            <ContainerTranslation id={"register.title"}/>
          </h1>
          <h2 className="page-title" style={{fontSize:"18px", textAlign: 'left', display: 'block', marginBottom: 20, color:"#212934"}}>
            <ContainerTranslation id={"register.subtitle1"}/>
          </h2>
          <div className="row">
            <div className="col half">
              <label className="" htmlFor="firstName" style={{color:"#333333"}}>First Name</label>
              <Field name="firstName" id="firstName" component={renderField} type="text" placeholder="Your first name ..."/>
            </div>
            <div className="col half">
              <label className="" htmlFor="lastName" style={{color:"#333333"}}>Last Name</label>
              <Field name="lastName" id="lastName" component={renderField} type="text"  placeholder="Your last name ..."/>
            </div>
          </div>
          <div className="row">
            <label className="" htmlFor="email" style={{color:"#333333"}}>Email</label>
            <Field name="email" id="email" component={renderField} type="email"  placeholder="Your email ..."/>
          </div>
          <div className="row">
            <label className="" htmlFor="phone" style={{color:"#333333"}}>Phone</label>
            <Field name="phone" id="phone" component={renderField} type="tel"  placeholder="Your phone number ..."/>
          </div>
          <p style={{fontStyle: 'italic', color: '#999', marginTop: 20}}>Password must contain one uppercase, one lowercase, one number and be 6 characters long</p>
          <div className="row">
            <label className="" htmlFor="password" style={{color:"#333333"}}>Password</label>
            <Field name="password" id="password" component={renderField} type="password" placeholder="Type your password ..."/>
          </div>
          <div className="row">
            <label className="" htmlFor="passwordConfirm" style={{color:"#333333"}}>Confirm Password</label>
            <Field name="passwordConfirm" id="passwordConfirm" component={renderField} type="password" placeholder="Confirm your password ..."/>
          </div>
          <h2 className="page-title" style={{fontSize:"18px", textAlign: 'left', display: 'block', marginTop:30, marginBottom: 20, color:"#212934"}}>
            <ContainerTranslation id={"register.subtitle2"}/>
          </h2>
          {this.props.languages.map((d,i) =>
            <div className="language" key={d.code} style={{display:'inline-block', width: '33.33%'}}>
              {this.state.languages.indexOf(d._id) != -1 ? (
                <a key={new Date().getTime()+d._id} style={{fontSize:'14px', color:'#000000', lineHeight:'36px'}} onClick={ (e) => this.toggleLanguage(d._id) }>
                  <span style={{fontSize:'24px', color:'#284390', cursor:'pointer', marginRight:10, verticalAlign: 'top'}}><i className="fas fa-check-square"></i></span>{d.name}
                </a>
              ):(
                <a key={new Date().getTime()+d._id} style={{fontSize:'14px', color:'#000000', lineHeight:'36px'}} onClick={ (e) => this.toggleLanguage(d._id) }>
                  <span style={{fontSize:'24px', color:'#E1E7EC', cursor:'pointer', marginRight:10, verticalAlign: 'top'}}><i className="far fa-square"></i></span>{d.name}
                </a>
              )}

            </div>
          )}

          {this.state.isTermsChecked === true ? (
            <button type="submit" className="btn btn-submit" style={{maxWidth:450, marginTop:50}}><ContainerTranslation id={"register.cta"}/></button>
          ):(
            <a className="btn" type="submit" className="btn btn-submit" style={{maxWidth:390, marginTop:50, cursor:'not-allowed', opacity:0.3, textAlign:'center'}}>
              <ContainerTranslation id={"register.cta"}/>
            </a>
          )}

          <div className="row" style={{display: "inline-block", marginRight: 20, textAlign:"center", width: "100%", verticalAlign:'top', marginTop:10}}>
            {this.state.isTermsChecked === true ? (
              <a key={new Date().getTime()+"termsCheck"} onClick={ (e) => this.toggleTerms(e, false) } style={{fontSize:'24px', color:'#284390', textAlign:'center', cursor:'pointer', display:'inline-block'}}><i className="fas fa-check-square"></i></a>
            ):(
              <a key={new Date().getTime()+"termsCheck"} onClick={ (e) => this.toggleTerms(e, true) } style={{fontSize:'24px', color:'#E1E7EC', textAlign:'center', cursor:'pointer', display:'inline-block'}}><i className="far fa-square"></i></a>
            )}
            <p style={{display:'inline-block', marginLeft:10, marginTop: 8, verticalAlign: 'top'}}><ContainerTranslation id={"question.terms"}/> <Link to="/terms"><ContainerTranslation id={"question.termsLink"}/></Link></p>
          </div>

          <Link to="/login" style={{display:"block",marginTop:"10px", textAlign:"center"}}>Already have an account ?</Link>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    languages: state.language.languages
    //authenticated: state.auth.authenticated,
  };
}

export default withRouter(connect(mapStateToProps, { registerUser, fetchLanguages })(form(Register)));
