import { getData, postData, putData, deleteData } from './index';
import { USER_ERROR, CREATE_USER, FETCH_USERS, FETCH_USER, UPDATE_USER, DELETE_USER } from './types';

//= ===============================
// User actions
//= ===============================

export function createUser(user) {
  const data = user;
  const url = '/user';
  return dispatch => postData(CREATE_USER, USER_ERROR, true, url, dispatch, data);
}

export function fetchUsers() {
  const url = '/user';
  return dispatch => getData(FETCH_USERS, USER_ERROR, true, url, dispatch);
}

export function fetchUser(userId) {
  const url = '/user/'+userId;
  return dispatch => getData(FETCH_USER, USER_ERROR, true, url, dispatch);
}

export function updateUser(user, userId) {
  const data = user;
  const url = '/user/'+userId
  return dispatch => putData(UPDATE_USER, USER_ERROR, true, url, dispatch, data);
}

export function deleteUser(user) {
  const url = '/user/'+user;
  return dispatch => deleteData(DELETE_USER, USER_ERROR, true, url, dispatch);
}
