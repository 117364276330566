import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { loginUser } from '../../actions/auth';
import ContainerTranslation from "../partials/containerTranslation";
import Button from "../partials/Button/Button"

const form = reduxForm({
  form: 'login',
});

class Login extends Component {

  constructor(props) {
    super(props);
    if (this.props.authenticated) {
      this.props.history.push('/dashboard');
    }
  }

  handleFormSubmit(formProps) {
    this.props.loginUser(formProps);
  }

  componentDidUpdate(prevProps) {
    var _this = this
    if (this.props.authenticated !== prevProps.authenticated) {
      setTimeout(function(){
        _this.props.history.push('/dashboard');
      }, 500);
    }
  }

  renderAlert() {
    if (this.props.errorMessage) {
      return (
        <div className="message error">
          <span><strong>Error!</strong> {this.props.errorMessage}</span>
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <div className="page">
        <div className="page-login">
          <div className="col-login">
            <div className="header-login">
              <h1 style={{marginBottom: 0}}>login</h1>
            </div>
            <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))} className="form-login">
              {this.renderAlert()}
              {/*<h1>Login</h1>*/}
              <div className="row" style={{textAlign: 'left'}}>
                <label className="" htmlFor="email"><ContainerTranslation id={"profile.email"}/></label>
                <Field name="email" placeholder="email" id="email" className="form-control" component="input" type="text" />
              </div>
              <div className="row" style={{textAlign: 'left'}}>
                <label className="" htmlFor="password"><ContainerTranslation id={"profile.password"}/></label>
                <Field name="password" placeholder="password" id="password" className="form-control" component="input" type="password" style={{marginBottom:0}}/>
                <Link to="/forgot-password" style={{display:"block",marginTop:5, textAlign:'left'}}><ContainerTranslation id={"global.forgotPassword"}/></Link>
              </div>
              <button type="submit" className="btn btn-submit"><ContainerTranslation id={"global.next"}/></button>
              <Link to="/register" className="btn btn-secondary" style={{marginTop:20, marginBottom:50, padding:0}}><ContainerTranslation id={"global.register"}/></Link>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.error,
    message: state.auth.message,
    authenticated: state.auth.authenticated,
  };
}

export default withRouter(connect(mapStateToProps, { loginUser })(form(Login)));
