import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchProfile } from '../../actions/index';
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';
import ContainerTranslation from "../partials/containerTranslation";
import List from "../partials/Menu/List";
import { updateQuestion, fetchQuestions } from '../../actions/question';
import _ from 'lodash'
import Select from 'react-select';
import Questions from '../question/questions';
import DashboardView from './dashboard-view';

class Dashboard extends Component {

  constructor(props) {
    super(props);
    const { params, updateQuestion, fetchQuestions} = this.props;
    this.state = {
      selectedMenu:null,
      view:'inbox',
      question:"",
      email:"",
      phone:"",
      language:"",
      filter:false
    };
    fetchQuestions()
  }

  componentDidUpdate(prevProps){
    const _this = this
    if(this.props.message === "Question successfully updated!"){
      this.props.fetchQuestions()
    }
    if((this.props.activeLanguage && !prevProps.activeLanguage) || this.props.activeLanguage !== prevProps.activeLanguage || !this.state.language){
      var foundLanguage = _.find(_this.props.languages, function(l){return l.code ===_this.props.activeLanguage.code})
      if(foundLanguage){
        this.setState({
          language:foundLanguage._id
        })
      }
    }
  }

  handleQuestionChange(e){
    this.setState({
      question:e.currentTarget.value
    })
  }

  toggleView(e, view){
    this.setState({
      view:view
    })
  }

  toggleFilter(e){
    var _this = this
    this.setState({
      filter:!_this.state.filter
    })
  }

  toggleWallPost(e, value, questionId){
    e.preventDefault()
    this.props.updateQuestion({
      isPublic:value
    }, questionId)
  }

  selectMenu(d){
    this.setState({
      selectedMenu:d
    })
  }

  getStatus(d){
    if(d.answer){
      return 'Responded'
    }else{
      return 'Respond'
    }
  }

  getStatusClass(d){
    if(!d.answer){
      return 'question-status'
    }else{
      return 'question-status disabled'
    }
  }

  render() {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const _this = this
    let questions = []
    if(this.props.questions){
      questions = _this.props.questions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      if(this.state.selectedMenu === 'menu1'){
        questions = _.filter(questions, function(q){
          return !q.answer || q.answer === ''
        })
      }
      if(this.state.selectedMenu === 'menu2'){
        questions = _.filter(questions, function(q){
          return q.answer && q.answer !== ''
        })
      }
      if(this.state.selectedMenu === 'menu3'){
        questions = _.filter(questions, function(q){
          return !q.isPublic
        })
      }
    }

    if(this.state.filter){
      questions = _.sortBy(questions, [function(q) { return q.language; }]);
    }

    var name = ""
    if(this.props.profile && this.props.profile.profile){
      name = this.props.profile.profile.firstName
    }

    var tabList = [
      {
        _id:"menu"+0,
        nameTranslate:"dashboard.menu1"
      },
      {
        _id:"menu"+1,
        nameTranslate:"dashboard.menu2"
      },
      {
        _id:"menu"+2,
        nameTranslate:"dashboard.menu3"
      },
      {
        _id:"menu"+3,
        nameTranslate:"dashboard.menu4"
      }
    ]

    const status = 'pending'

    return (
      <div>
        <div style={{display:'block', textAlign:'center', background: '#FFF6E8', paddingTop: 30, paddingBottom: 50}}>
          <h1 className="page-title" style={{fontWeight:'normal'}}><ContainerTranslation id={"dashboard.welcome"}/> {name}</h1>
        </div>
        <div className="page-container" style={{paddingTop:0}}>
          <div style={{display:'block', textAlign:'center'}}>
            <div className='tabs'>
              <a className={(this.state.view === 'inbox' ? 'active tab' : 'tab')} onClick={ (e) => this.toggleView(e, 'inbox') }>
                <i className="fas fa-inbox"></i><ContainerTranslation id={"dashboard.tab1"}/>
              </a>
              <a className={(this.state.view === 'questions' ? 'active tab' : 'tab')} onClick={ (e) => this.toggleView(e, 'questions') }>
                <i className="fas fa-th-large"></i><ContainerTranslation id={"dashboard.tab2"}/>
              </a>
            </div>
            {this.state.view === 'inbox' ? (
              <DashboardView/>
            ):(
              null
            )}
            {this.state.view === 'questions' ? (
              <div style={{textAlign: 'left', marginTop:10}}>
                <Questions
                  language={_this.state.language}
                />
              </div>
            ):(
              null
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.user.profile,
    languages: state.language.languages,
    questions: state.question.questions,
    message: state.question.message,
  };
}

export default withLocalize(connect(mapStateToProps, { fetchProfile, updateQuestion, fetchQuestions })(Dashboard));
