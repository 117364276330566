//= =====================
// Auth Actions
//= =====================
export const AUTH_USER = 'auth_user',
  REGISTER_USER = 'register_user',
  UNAUTH_USER = 'unauth_user',
  AUTH_ERROR = 'auth_error',
  FORGOT_PASSWORD_REQUEST = 'forgot_password_request',
  RESET_PASSWORD_REQUEST = 'reset_password_request',
  PROTECTED_TEST = 'protected_test';

//= =====================
// User Profile Actions
//= =====================
export const FETCH_PROFILE = 'fetch_profile';

//= =====================
// Users Actions
//= =====================
export const FETCH_USERS = 'fetch_users',
  CREATE_USER = 'create_user',
  FETCH_USER = 'fetch_user',
  UPDATE_USER = 'update_user',
  DELETE_USER = 'delete_user',
  USER_ERROR = 'user_error';

//= =====================
// Alerts Actions
//= =====================
export const FETCH_QUESTIONS = 'fetch_questions',
  CREATE_QUESTION = 'create_question',
  INIT_MESSAGE_QUESTION = 'init_message_question',
  UPDATE_QUESTION = 'update_question',
  DELETE_QUESTION = 'delete_question',
  FETCH_QUESTION = 'fetch_question',
  QUESTION_ERROR = 'question_error';

//= =====================
// Group Actions
//= =====================
export const FETCH_LANGUAGES = 'fetch_languages',
  CREATE_LANGUAGE = 'create_language',
  UPDATE_LANGUAGE = 'update_language',
  DELETE_LANGUAGE = 'delete_language',
  FETCH_LANGUAGE = 'fetch_language',
  SELECT_LANGUAGE = 'select_language',
  LANGUAGE_ERROR = 'language_error';

//= =====================
// Page Actions
//= =====================
export const STATIC_ERROR = 'static_error';
