import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchProfile } from '../../actions/index';
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';
import ContainerTranslation from "../partials/containerTranslation";
import { createQuestion, initMessage } from '../../actions/question';
import { fetchLanguages } from '../../actions/language';
import _ from 'lodash'
import Select from 'react-select';
import Questions from './questions';


class Question extends Component {

  constructor(props) {
    super(props);
    const { params, createQuestion, fetchLanguages, initMessage} = this.props;
    this.state = {
      displayQuestion:false,
      isTermsChecked:false,
      isSubmitted:false,
      question:"",
      email:"",
      phone:"",
      language:"",
      listening:false
    };
    fetchLanguages()
  }

  componentDidUpdate(prevProps){
    const _this = this
    if(this.props.message === "question saved" && this.state.isSubmitted === false){
      this.setState({
        isSubmitted:true
      })
    }
    if(this.props.message === "" && this.state.isSubmitted === true){
      this.setState({
        isSubmitted:false,
        displayQuestion:false
      })
    }

    if((this.props.activeLanguage && !prevProps.activeLanguage) || this.props.activeLanguage !== prevProps.activeLanguage || !this.state.language){
      if(this.props.activeLanguage && this.props.activeLanguage.code){
        var foundLanguage = _.find(_this.props.languages, function(l){return l.code === _this.props.activeLanguage.code})
        if(foundLanguage){
          this.setState({
            language:foundLanguage._id
          })
        }
      }
    }
  }

  handleQuestionChange(e){
    this.setState({
      question:e.currentTarget.value
    })
  }

  handleEmailChange(e){
    this.setState({
      email:e.currentTarget.value
    })
  }

  handlePhoneChange(e){
    this.setState({
      phone:e.currentTarget.value
    })
  }

  handleLanguageChange(e){
    this.setState({
      language:e.value
    })
  }

  toggleQuestion(e, state){
    this.setState({
      displayQuestion:state
    })
  }

  toggleTerms(e, value){
    e.preventDefault()
    this.setState({
      isTermsChecked:value
    })
  }

  back(e){
    this.props.initMessage()
  }

  top(e){
    window.scrollTo(0, 0)
  }

  sayQuestion(e){
    var _this = this
    var SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition || window.mozSpeechRecognition || window.msSpeechRecognition
    this.recognition = new SpeechRecognition();
    this.recognition.lang = this.props.activeLanguage.code
    this.recognition.start();
    _this.setState({
      listening:true
    })
    this.recognition.onresult = function(event) {
      var speech = event.results[0][0].transcript;
      _this.setState({
        listening:false,
        question:speech
      })
    }

    this.recognition.onspeechend = function() {
      _this.recognition.stop();
    }
  }

  sendQuestion(){
    const _this = this
    this.props.createQuestion({
      isPublic:false,
      question:_this.state.question,
      author:{
        email:_this.state.email,
        phone:_this.state.phone
      },
      language:_this.state.language
    })
  }

  render() {
    const _this = this

    let languageOptions = []
    if(this.props.languages && this.props.languages.length){
      languageOptions = _.map(_this.props.languages, function(l){
        return {
          value: l._id, label: l.name
        }
      })
    }

    let languageValue = _.find(languageOptions, function(l){
      return l.value.toString() === _this.state.language.toString()
    })

    return (
      <div style={{zIndex:1}}>
        <div className="page-container">
          <div style={{display:'block', textAlign:'center'}}>
            {this.state.isSubmitted ? (
              <>
                <div className="question-check"><i className="fas fa-check"></i></div>
                <h1 className="page-title"><ContainerTranslation id={"question.submitted"}/></h1>
                <h2 className="page-baseline" style={{maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', marginTop: 20, marginBottom: 30}}><ContainerTranslation id={"question.response"}/></h2>
                <a className="btn" style={{width:100}} onClick={this.back.bind(this)}><ContainerTranslation id={"question.back"}/></a>
              </>
            ):(
              <>
                <h1 className="page-title"><ContainerTranslation id={"question.title1"}/> <span style={{color:"#686EFC"}}><ContainerTranslation id={"question.title2"}/></span></h1>
                <h2 className="page-baseline" style={{maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', marginTop: 20, marginBottom: 30}}><ContainerTranslation id={"question.baseline"}/></h2>
              </>
            )}
            {!this.state.isSubmitted ? (
              <div>
                {this.state.displayQuestion ? (
                  <div style={{maxWidth:720, marginLeft: 'auto', marginRight: 'auto'}}>
                    {this.state.listening ? (
                      <a key='listening' className="btn-mic" style={{position: 'absolute', marginLeft: '300px'}}>
                        <div><i className="fa fa-circle-notch fa-spin" style={{color: 'rgb(97, 135, 245)'}}></i></div>
                      </a>
                    ):(
                      <a key='not-listening' className="btn-mic" onClick={ (e) => this.sayQuestion(e) } style={{position: 'absolute', marginLeft: '300px'}}>
                        <div><i className="fas fa-microphone"></i></div>
                      </a>
                    )}
                    <textarea value={this.state.question} onChange={ (e) => this.handleQuestionChange(e) }>
                    </textarea>
                    <div>
                      <div className="row" style={{display: "inline-block", marginRight: 20, textAlign:"left", width: "calc(55% - 10px)"}}>
                        <label className="" htmlFor="email" style={{fontSize:'18px', fontWeight:'bold', color:'#525C68', marginBottom:10, display:'block'}}><ContainerTranslation id={"question.email"}/></label>
                        <input name="email" placeholder="email" id="email" className="form-control" type="email" onChange={ (e) => this.handleEmailChange(e) }/>
                      </div>
                      {this.state.language && this.state.isTermsChecked === true && this.state.question ? (
                        <a className="btn" onClick={this.sendQuestion.bind(this)} style={{marginTop: '20px', marginLeft: 0, display: 'inline-block', width: "calc(45% - 70px)"}}>
                          <ContainerTranslation id={"question.submit"}/>
                        </a>
                      ):(
                        <a className="btn" style={{marginTop: '20px', marginLeft: 0, display: 'inline-block', width: "calc(45% - 70px)", cursor:'not-allowed', opacity:0.3}}>
                          <ContainerTranslation id={"question.submit"}/>
                        </a>
                      )}
                    </div>
                    <div>
                      <div className="row" style={{display: "inline-block", marginRight: 20, textAlign:"left", width: "100%", verticalAlign:'top'}}>
                        {this.state.isTermsChecked === true ? (
                          <a key={new Date().getTime()+"termsCheck"} onClick={ (e) => this.toggleTerms(e, false) } style={{fontSize:'24px', color:'#284390', textAlign:'center', cursor:'pointer', display:'inline-block'}}><i className="fas fa-check-square"></i></a>
                        ):(
                          <a key={new Date().getTime()+"termsCheck"} onClick={ (e) => this.toggleTerms(e, true) } style={{fontSize:'24px', color:'#E1E7EC', textAlign:'center', cursor:'pointer', display:'inline-block'}}><i className="far fa-square"></i></a>
                        )}
                        <p style={{display:'inline-block', marginLeft:10, marginTop: 8, verticalAlign: 'top'}}><ContainerTranslation id={"question.terms"}/> <Link to="/terms"><ContainerTranslation id={"question.termsLink"}/></Link></p>
                      </div>

                    </div>
                  </div>
                ) : (
                  <div className="btn-question" onClick={ (e) => this.toggleQuestion(e, true) }>
                    <ContainerTranslation id={"question.placeHolder"}/>
                    <a className="btn-mic"><div><i className="fas fa-microphone"></i></div></a>
                  </div>
                )}
              </div>
            ):(
              null
            )}
            <div>
            <div style={{textAlign: 'left', marginTop:120}}>

              <Questions
                language={_this.state.language}
              />

            </div>
            </div>
            <h2 className="page-baseline" style={{maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', color:'#212934', fontSize:'30px', marginTop:150}}><ContainerTranslation id={"question.end"}/></h2>
            <a className="btn" style={{width:160, marginBottom:100}} onClick={this.top.bind(this)}><ContainerTranslation id={"question.ask"}/></a>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.user.profile,
    languages: state.language.languages,
    message: state.question.message
  };
}

export default withLocalize(connect(mapStateToProps, { fetchProfile, createQuestion, fetchLanguages, initMessage })(Question));
