import React, { Component } from 'react';
import { connect } from 'react-redux';
import { renderToStaticMarkup } from "react-dom/server";
import HeaderTemplate from './partials/header';
import FooterTemplate from './partials/footer';
import Message from './partials/Message/Message';
import ContainerTranslation from "./partials/containerTranslation";
import { withLocalize } from "react-localize-redux";
import { fetchLanguages } from './../actions/language';
import _ from 'lodash'
import arTranslations from "./../assets/translations/ar.json";
import bnTranslations from "./../assets/translations/bn.json";
import enTranslations from "./../assets/translations/en.json";
import esTranslations from "./../assets/translations/es.json";
import faTranslations from "./../assets/translations/fa.json";
import frTranslations from "./../assets/translations/fr.json";
import taTranslations from "./../assets/translations/ta.json";
import viTranslations from "./../assets/translations/vi.json";
import tuTranslations from "./../assets/translations/tu.json";
import globalTranslations from "./../assets/translations/global.json";

const onMissingTranslation = ({ defaultTranslation }) => {
  return ''
};

class Page extends Component {

  constructor(props) {
    super(props);
    const { params, fetchLanguages } = this.props;

    if(this.props.location.pathname === "/"){
      this.props.history.push('/question');
    }

  }

  componentDidUpdate(prevProps) {
    var _this = this
    if(this.props.languages && this.props.languages.length > 0){
      if(!prevProps.languages || prevProps.languages.length === 0){
        const languages = _.map(_this.props.languages, function(l){
          return {
            name:l.name,
            code:l.code
          }
        })
        const defaultLanguage = localStorage.getItem("languageCode") || 'en';
        const availableLanguages = {
          'ar':arTranslations,
          'bn':bnTranslations,
          'en':enTranslations,
          'es':esTranslations,
          'fa':faTranslations,
          'fr':frTranslations,
          'ta':taTranslations,
          'vi':viTranslations,
          'tu':tuTranslations
        }

        _.each(languages, function(language){
          if(availableLanguages[language.code]){
            _.forEach(globalTranslations, function(value, key) {
              _.forEach(globalTranslations[key], function(value2, key2) {
                if(availableLanguages[language.code] && availableLanguages[language.code][key] && availableLanguages[language.code][key][key2]){
                  globalTranslations[key][key2].push(availableLanguages[language.code][key][key2])
                }else{
                  globalTranslations[key][key2].push("")
                }
              });
            });
          }else{
            _.forEach(globalTranslations, function(value, key) {
              _.forEach(globalTranslations[key], function(value2, key2) {
                globalTranslations[key][key2].push("")
              });
            });
          }
        })

        this.props.initialize({
          languages: languages,
          translation: globalTranslations,
          options: {
            defaultLanguage,
            renderToStaticMarkup : false,
            renderInnerHtml : true,
            onMissingTranslation
         }
       });
      }
    }

    var prevLangCode = null
    var curLangCode = null;

    if(!prevProps.activeLanguage){
      prevLangCode = null
      if(this.props.activeLanguage){
        curLangCode = this.props.activeLanguage.code
      }else{
        curLangCode = null
      }
    }else{
      prevLangCode = prevProps.activeLanguage.code
      if(this.props.activeLanguage){
        curLangCode = this.props.activeLanguage.code
      }else{
        curLangCode = null
      }
    }

    const hasLanguageChanged = prevLangCode !== curLangCode;

    if (hasLanguageChanged) {
      localStorage.setItem('languageCode', curLangCode);
    }
  }

  render() {

    return (
      <div className="mainContainer">
        <HeaderTemplate title="Kelvi" location={this.props.location.pathname}/>
        <div>
          {this.props.children}
        </div>
        <FooterTemplate location={this.props.location.pathname}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    languages: state.language.languages
  };
}


export default withLocalize(connect(mapStateToProps, {fetchLanguages})(Page));
