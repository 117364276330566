import React from "react";
import { withLocalize } from "react-localize-redux";
import _ from 'lodash';

const LanguageToggle = ({ languages, activeLanguage, setActiveLanguage }) => (
    <div>
      {languages.map((d,i) =>
        <div className="language" key={d.code}>
          <a style={{fontSize:'18px', color:'#000000', lineHeight:'36px'}} onClick={ (e) => setActiveLanguage(d.code) }>{d.name}</a>
        </div>
      )}
    </div>
  );

export default withLocalize(LanguageToggle);
