import React, { Component } from 'react';
import { Router, Route, IndexRoute } from 'react-router';
import { createBrowserHistory } from 'history';
import { LocalizeProvider } from "react-localize-redux";

import ReactGA from 'react-ga';

import Page from './page';
import NotFoundPage from './pages/not-found-page';
import About from './pages/about';
import Terms from './pages/terms';
import Privacy from './pages/privacy';

import Register from './auth/register';
import Login from './auth/login';
import Logout from './auth/logout';
import ForgotPassword from './auth/forgot_password';
import ResetPassword from './auth/reset_password';
import Profile from './profile/profile';

import Question from './question/question';
import Dashboard from './dashboard/dashboard';
import QuestionView from './dashboard/question-view';
import User from './user/user';

// Import higher order components
import RequireAuth from './auth/require_auth';


// Initialize Google Analytics
ReactGA.initialize('UA-38976794-1');
function logPageView() {
  ReactGA.pageview(window.location.pathname);
}

class App extends Component {
  render() {
    const login = function login(props){
      return <Page title="Login" {...props}><Login /></Page>
    }
    const register = function login(props){
      return <Page title="Register" {...props}><Register /></Page>
    }
    const logout = function logout(props){
      return <Page title="Logout" {...props}><Logout /></Page>
    }

    return (
      <LocalizeProvider>
        <Router history={createBrowserHistory()} onUpdate={logPageView}>
          <Route exact path="/" component={(props) => <Page title="Question" {...props}><Question /></Page>} />

          <Route path="/register" component={register} />
          <Route path="/login" component={login} />
          <Route path="/logout" component={logout} />
          <Route path="/forgot-password" component={(props) => <Page title="ForgotPassword" {...props}><ForgotPassword /></Page>} />
          <Route path="/reset-password/:resetToken" component={(props) => <Page title="ResetPassword" {...props}><ResetPassword /></Page>} />

          <Route path="/profile" component={RequireAuth((props) => <Page title="Profile" {...props}><Profile /></Page>)} />

          <Route exact path="/question" component={(props) => <Page title="Question" {...props}><Question /></Page>} />
          <Route path='/dashboard/:id' component={RequireAuth((props) => <Page title="QuestionView" {...props}><QuestionView /></Page>)} />
          <Route exact path="/dashboard" component={RequireAuth((props) => <Page title="Dashboard" {...props}><Dashboard /></Page>)} />
          <Route exact path="/user" component={RequireAuth((props) => <Page title="User" {...props}><User /></Page>)} />

          <Route path="/terms" component={(props) => <Page title="Terms and conditions" {...props}><Terms /></Page>} />
          <Route path="/privacy" component={(props) => <Page title="Privacy Policy" {...props}><Privacy /></Page>} />
          <Route path="/about" component={(props) => <Page title="About" {...props}><About /></Page>} />

          {/*<Route path="*" component={NotFoundPage} />*/}
        </Router>
      </LocalizeProvider>
    );
  }
}

export default App;
