import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContainerTranslation from "../partials/containerTranslation";
import { fetchPublicQuestions } from '../../actions/question';
import { withLocalize } from "react-localize-redux";
import _ from 'lodash'

/*Pinterest Style !*/
var resizeMasonryItem = function(item){
  var grid = document.getElementsByClassName('questions')[0],
      rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
      rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
  var rowSpan = Math.ceil((item.querySelector('.question-wrapper').getBoundingClientRect().height+80+rowGap)/(rowHeight+rowGap));

  item.style.gridRowEnd = 'span '+rowSpan;
}

var resizeAllMasonryItems = function(){
  var allItems = document.getElementsByClassName('question');
  for(var i=0;i<allItems.length;i++){
    allItems[i].style.opacity = 0;
  }
  setTimeout(function(){
    for(var i=0;i<allItems.length;i++){
      allItems[i].style.opacity = 1;
      resizeMasonryItem(allItems[i]);
    }
  }, 100);
}

var masonryEvents = ['load', 'resize'];

var voices = []
function setSpeech() {
    return new Promise(
        function (resolve, reject) {
            let synth = window.speechSynthesis;
            let id;

            id = setInterval(() => {
                if (synth.getVoices().length !== 0) {
                    resolve(synth.getVoices());
                    clearInterval(id);
                }
            }, 10);
        }
    )
}

let s = setSpeech();
s.then((v) => voices = v);

class Questions extends Component {
  constructor(props) {
    super(props);
    const { fetchPublicQuestions } = this.props;
    this.state = {
      loading:false,
      questions:[],
      search:"",
      reading:null
    };
    fetchPublicQuestions()
  }

  componentDidMount(){
    var _this = this
    window.addEventListener('scroll',(e) => _this.manageScroll(e))
    masonryEvents.forEach( function(event) {
      window.addEventListener(event, resizeAllMasonryItems);
    });
    let publicQuestions = []
    if(this.props.questions){
      publicQuestions = _.filter(_this.props.questions, function(question){return question.isPublic === true}).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      if(_this.props.language && _this.props.language.toString()){
        publicQuestions = _.filter(publicQuestions, function(question){
          return question.language.toString() === _this.props.language.toString()
        })
      }

      this.setState({
        questions:publicQuestions
      })
    }
    resizeAllMasonryItems()
  }

  componentDidUpdate(prevProps){
    var _this = this
    let publicQuestions = []
    if((this.props.questions && !prevProps.questions) || this.props.questions.length !== prevProps.questions.length || this.props.language !== prevProps.language){
      publicQuestions = _.filter(_this.props.questions, function(question){return question.isPublic === true}).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      if(_this.props.language && _this.props.language.toString()){
        publicQuestions = _.filter(publicQuestions, function(question){
          if(question.language._id){
            return question.language._id.toString() === _this.props.language.toString()
          }
          return question.language.toString() === _this.props.language.toString()
        })
      }

      this.setState({
        questions:publicQuestions
      })
    }
    if(this.state.questions.length !== prevProps.questions.length){
      resizeAllMasonryItems()
    }
  }

  componentWillUnmount() {
    var _this = this
    window.removeEventListener('scroll',(e) => _this.manageScroll(e))
    masonryEvents.forEach( function(event) {
      window.removeEventListener(event, resizeAllMasonryItems);
    });
  }

  manageScroll(e){
    var element = document.getElementById('container-visualisation')
    if(element){
      element = element.getBoundingClientRect()
      if(element.bottom <= window.innerHeight){
        console.log('load !')
      }
    }
  }

  readQuestion(e, d){
    var _this = this

    this.speaker = new SpeechSynthesisUtterance();
    this.speaker.lang = this.props.activeLanguage.code
    this.speaker.text = d.question+" "+d.answer;

    var filteredVoices = _.filter(voices, function(v){
      return v.lang.startsWith(_this.props.activeLanguage.code)
    })
    if(filteredVoices && filteredVoices.length){
      this.speaker.voice = filteredVoices[Math.round(Math.floor()*filteredVoices.length)]
    }
    speechSynthesis.speak(this.speaker);

    this.setState({
      reading:d._id
    })
  }

  cancelReading(e){
    speechSynthesis.cancel()
    this.setState({
      reading:null
    })
  }

  handleSearchChange(e){
    this.setState({
      search:e.currentTarget.value
    })
  }

  render() {
    const _this = this

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    let optionsCode = "en"
    if(_this.props.activeLanguage && _this.props.activeLanguage.code){
      optionsCode = _this.props.activeLanguage.code
    }

    let publicQuestions = this.state.questions

    if(this.state && this.state.search && this.state.search.length){
      publicQuestions = _.filter(publicQuestions, function(q){
        if(q.question && q.question.search(_this.state.search) !== -1){
           return true
        }if(q.answer && q.answer.search(_this.state.search) !== -1){
           return true
        }
        return false
      })
    }

    return (
      <>
        {this.state.loading ? (
          <div style={{marginTop:50, display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
            <><i className="fa fa-circle-notch fa-spin fa-3x" style={{color: 'rgb(97, 135, 245)'}}></i></>
          </div>
        ):(
          null
        )}
        <div style={{display: 'block'}}>
          <h2 style={{fontSize:"25px", display:'inline-block', color:"#2F3955", fontWeight:'bold'}}><ContainerTranslation id={"question.previous"}/></h2>
          <input name="search" placeholder="Search..." id="search" className="form-control" type="text"
            style={{display:'inline-block', color:"#2F3955", float:'right', width: '224px', background: '#ECEFF2', border: 'none', height: '48px', lineHeight: '48px', borderRadius: '40px'}}
            onChange={ (e) => this.handleSearchChange(e) }
          />
          <div style={{display: 'inline-block', float: 'right', marginRight: -240, verticalAlign: 'top', marginTop: 20, color: '#212934'}}><i className="fas fa-search"></i></div>
        </div>
        <div className="questions">
          {publicQuestions.map(d =>
            <div className="container question" key={d._id} style={{backgroundColor:d.color}}>
              {this.state.reading === d._id ? (
                <a key="read-pause" style={{fontSize:"16px"}} className="btn-speak" onClick={ (e) => this.cancelReading(e) }><i className="fas fa-pause"></i></a>
              ):(
                <a key="read-play" className="btn-speak" onClick={ (e) => this.readQuestion(e, d) }><i className="fas fa-volume-down"></i></a>
              )}
              <div className="question-wrapper" key={d._id}>
                <p style={{color:"#A3B9CE", margin:0}}>{new Date(d.createdAt).toLocaleDateString(optionsCode, options)}</p>
                <p style={{fontSize:'30px'}}>{d.question}</p>
                <p style={{fontSize:'18px'}}>{d.answer}</p>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    questions: state.question.questions,
  };
}

export default withLocalize(connect(mapStateToProps, {fetchPublicQuestions})(Questions));
