import React, { Component } from 'react';
import ContainerTranslation from "../partials/containerTranslation";
import illustration from '../../assets/images/illustration.png';
import ecosystem from '../../assets/images/ecosystem.png';
import partner1 from '../../assets/images/firstandforemost.png';
import partner2 from '../../assets/images/bakasable.png';

class About extends Component {

  render() {
    return (
      <div className="about">
        <div className="col-container">
          <div className="col col-1-3">
            <img src={illustration}/>
          </div>
          <div className="col col-2-3">
            <h1 className="title"><ContainerTranslation id={"about.title"}/></h1>
            <p className="content"><ContainerTranslation id={"about.text"}/></p>
          </div>
        </div>
        <div className="withSeparator">
          <h2 className="subTitle"><ContainerTranslation id={"about.title2"}/></h2>
          <p className="content"><ContainerTranslation id={"about.text2"}/></p>
        </div>
        <div className="ecosystem">
          <img src={ecosystem} style={{maxWidth:1250}}/>
        </div>
        <div className="who">
          <div className="whoContent">
            <h2 className="subTitle"><ContainerTranslation id={"about.whoTitle"}/></h2>
            <p className="content" style={{marginBottom: 100}}><ContainerTranslation id={"about.whoText"}/></p>
            <div className="col half" style={{marginRight: 80}}>
              <div className="card">
                <img src={partner1}/>
                <h2 className="cardTitle"><ContainerTranslation id={"about.partner1Name"}/></h2>
                <p className="cardContent"><ContainerTranslation id={"about.partner1Text"}/></p>
                <a href="https://firstandforemost.co/"><div><i className="fas fa-angle-right"></i></div></a>
              </div>
            </div>
            <div className="col half">
            <div className="card">
              <img src={partner2}/>
              <h2 className="cardTitle"><ContainerTranslation id={"about.partner2Name"}/></h2>
              <p className="cardContent"><ContainerTranslation id={"about.partner2Text"}/></p>
              <a href="https://bakasable.fr/"><div><i className="fas fa-angle-right"></i></div></a>
            </div>
            </div>
          </div>
        </div>
        <div className="baseline">
          <h2 style={{maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', color:'#212934', fontSize:'30px', marginTop:150}}><ContainerTranslation id={"about.baseline"}/></h2>
          <a className="btn" style={{width:160, marginBottom:100}} href="mailto:contact@kelvi.org?subject=Hello!"><ContainerTranslation id={"about.baselineBtn"}/></a>
        </div>
      </div>
    );
  }
}

export default About;
